import { Link } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import Transitions from "./Transition";

export default function Teams() {
    const { loading, error, data } = useFetch(process.env.REACT_APP_API_URL +'/mannschafts')

    if (loading) return <p></p>
    if (error) return <p>Error</p>

    return (
        <Transitions>
        <div className="p-12">
            <div className="container mx-auto">
                <h1 className="text-5xl text-gray-600 flex justify-center mb-12">Die Mannschaften des TC Bodenmais</h1>
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 overflow-hidden">
                    {data.map(mannschaft => (
                    <div key={mannschaft.id} >
                        <Link to="/team" state={{ id : mannschaft.id}}>   
                            <div className="group block max-h-64 max-w-md relative overflow-hidden rounded-lg shadow-lg cursor-pointer">
                                <img src={process.env.REACT_APP_API_URL + mannschaft.teamfoto.formats.small.url} alt="Teamfoto" className="transition-opacity duration-500 group-hover:opacity-50" />
                                <div className="absolute bottom-0 right-0 px-28 py-16">
                                    <h4 className="mb-3 text-5xl font-semibold text-gray-600 opacity-0 group-hover:opacity-100">{mannschaft.name}</h4>
                                </div>    
                            </div>
                        </Link>
                    </div>
                    ))}
                </div>
            </div>
        </div>
        </Transitions>
    )  
}