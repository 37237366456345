import title from "../img/background.jpg"
import News from "./News";
import Transitions from "./Transition";

export default function Home() {
    return (
        <Transitions>
        <div className="container mx-auto max-w-full">
            <div className="flex">
                <img src={title} alt="tennis field" className="object-cover w-full" />
            </div>
            <div>
                <News />
            </div>
        </div>
        </Transitions>
    )
}