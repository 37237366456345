import { useLocation } from "react-router-dom"
import useFetch from "../hooks/useFetch";
import Transitions from "./Transition";

export default function SingleTeam() {
    const location = useLocation()
    const { id } = location.state

    const { loading, error, data } = useFetch(process.env.REACT_APP_API_URL +'/mannschafts/' + id)
    if (loading) return <p></p>
    if (error) return <p>Error</p>
    
    const mannschaft = data

    return (
        <Transitions>
            <main className="p-12">
                <div className="container mx-auto">
                    <h1 className="text-5xl text-gray-600 flex justify-center mb-12">{mannschaft.name}</h1>
                    <img src={process.env.REACT_APP_API_URL + mannschaft.teamfoto.formats.large.url} alt="Teamfoto" className="mx-auto w-3/4 h-3/4 rounded-lg" />
                    <p className="px-6 pt-4 pb-2">{mannschaft.beschreibung}</p>
                </div>
            </main>
        </Transitions>
    )
}