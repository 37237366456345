import {useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import moment from "moment";
import { Types } from '../constants/bookingEnum'

let errorCounter = 0;

const authHeader= () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
        console.log("OK")
        return { Authorization: 'Bearer ' + user };
    } else {
        return {};
    }
}


function ApiRequest(title, start, end, resourceId, typeId) {
    axios.post(process.env.REACT_APP_API_URL + '/events', {
            title: title,
            start: start,
            end: end,
            resourceId: resourceId,
            typeId: typeId
        }, {
            headers: authHeader()
        }
    )
        .then(function (response) {
            console.log("Success")
        })
        .catch(function (error) {
            console.log(error)
            errorCounter = 1;
        })
}


export default function RepeatingEvents() {


    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState('');
    const [type, setType] = useState('');
    const [title, setTitle] = useState('');
    const [place, setPlace] = useState('');
    const [iteration, setIteration] = useState('');

    const submit = () => {
        let startTimeFormated = moment(startDate).toDate();
        let endTimeFormated = moment(endDate).toDate();
        for (let i = 0; i < iteration; i++){
            if (i === 0){
                ApiRequest(title, startTimeFormated, endTimeFormated, place, type);
                continue;
            }
            startTimeFormated = moment(startTimeFormated).add(7, 'days').toDate();
            endTimeFormated = moment(endTimeFormated).add(7, 'days').toDate();
            ApiRequest(title, startTimeFormated, endTimeFormated, place, type)
        }
        if (errorCounter === 1){
            toast.error("Datensatz wurde nicht vollständig übertragen")
            return;
        }
        toast.success("Alle Events wurden gespeichert")

    }

    return (
        <div className="grid grid-cols-2 gap-y-2 p-12">
            <div className="flex items-center justify-center">
                <label>Start:</label>
            </div>
            <div className="flex items-center justify-center">
                <input type={"datetime-local"} step={1800} value={startDate} name="startDate" onChange={e => setStartDate(e.target.value)}/>
            </div>
            <div className="flex items-center justify-center">
                <label>Ende:</label>
            </div>
            <div className="flex items-center justify-center">
                <input type={"datetime-local"} value={endDate} name="endDate" onChange={e => setEndDate(e.target.value)}/>
            </div>
            <div className="flex items-center justify-center">
                <label>Type:</label>
            </div>
            <div className="flex items-center justify-center">
                <select name="type" id="type" onChange={e => setType(e.target.value)}>
                    <option value={Types.User}>User</option>
                    <option value={Types.Guest}>Gast</option>
                    <option value={Types.Training}>Training</option>
                    <option value={Types.Game}>Spiel</option>
                </select>
            </div>
            <div className="flex items-center justify-center">
                <label>Wiederholungen:</label>
            </div>
            <div className="flex items-center justify-center">
                <input type={"number"} value={iteration} name="iteration" onChange={e => setIteration(e.target.value)}/>
            </div>
            <div className="flex items-center justify-center">
                <label>Event-Name:</label>
            </div>
            <div className="flex items-center justify-center">
                <input type={"text"} value={title} name="title" onChange={e => setTitle(e.target.value)}/>
            </div>
            <div className="flex items-center justify-center">
                <label>Platz:</label>
            </div>
            <div className="flex items-center justify-center">
                <input type={"number"} value={place} name="place" onChange={e => setPlace(e.target.value)}/>
            </div>
            <div className="flex items-center justify-center col-span-2">
                <button onClick={submit} className="m-4 w-64 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Submit</button>
            </div>
        </div>
    )
}