import useFetch from "../hooks/useFetch";
import { ClipboardDocumentListIcon } from "@heroicons/react/24/outline"
import Transitions from "./Transition";

export default function About() {
    const { loading, error, data } = useFetch(process.env.REACT_APP_API_URL + '/vorstandschafts')

    if (loading) return <p></p>
    if (error) return <p>Error</p>
    return (
        <Transitions>
            <div className="p-12 container mx-auto">
                <a href={process.env.REACT_APP_API_URL + "/uploads/Beitrittserklaerung_TCB_230712_143200_df3a875019.pdf"}>
                    <div className="flex justify-center">
                        <div className="">
                            <ClipboardDocumentListIcon className="h-16 lg:h-24 w-16 lg:w-24" />
                        </div> 
                        <div className="self-center">
                            <p className="text-5xl font-semibold text-gray-600">Mitglied werden</p>
                        </div>
                    </div>
                </a>
                <h2 className="py-24 text-3xl md:text-4xl lg:text-5xl font-semibold text-gray-600 text-center">Vorstandschaft</h2>
                <div className="grid grid-cols-1 gap-8">
                    {data.map(vorstandschaft => (
                    <div key={vorstandschaft.id} >
                        <div className="grid grid-rows-2 grid-cols-3 gap-4">
                            <div className="row-span-2">
                                <img src={process.env.REACT_APP_API_URL + vorstandschaft.image.formats.small.url} alt="Teamfoto" className="" />
                            </div>                       
                            <div className="col-span-2 row-span-2 self-center">
                                <p className="text-2xl md:text-3xl lg:text-5xl font-semibold text-gray-600">{vorstandschaft.role}</p>
                                <p className="text-2xl md:text-3xl lg:text-5xl text-gray-600">{vorstandschaft.name}</p>
                            </div> 
                        </div>
                    </div>
                    ))}
                </div>
            </div>
        </Transitions>
    )
}