import { useLocation } from "react-router-dom"
import useFetch from "../hooks/useFetch";
import ReactMarkdown from "react-markdown";
import Transitions from "./Transition";

export default function SingleNews() {
    const location = useLocation()
    const { id } = location.state

    const { loading, error, data } = useFetch(process.env.REACT_APP_API_URL +'/posts/' + id)
    if (loading) return <p></p>
    if (error) return <p>Error</p>

    const post = data

    return (
        <Transitions>
            <main className="p-12">
                <div className="container mx-auto">
                    <h1 className="text-5xl text-gray-600 flex justify-center mb-12">{post.description}</h1>
                    <img src={process.env.REACT_APP_API_URL + post.image.url} alt="news article" className="mx-auto w-full lg:w-1/2 h-1/2 rounded-lg" />
                    <ReactMarkdown children={post.text} className="markdown"/>
                </div>
            </main>
        </Transitions>
    )
}