import { Route, Routes, Navigate, Outlet} from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { AnimatePresence } from 'framer-motion'
import { useLocation } from "react-router-dom";
import Eror404 from "./components/Error404";
import Home from "./components/Home";
import About from "./components/About";
import SingleTeam from "./components/SingleTeam";
import Teams from "./components/Teams";
import SingleNews from "./components/SingleNews";
import Booking from "./components/Booking";
import LandingPageBooking from "./components/LandingPageBooking"
import Login from "./components/Login";
import NavBar from "./components/NavBar";
import authService from "./services/auth.service";
import Register from "./components/Register";
import { ToastContainer } from "react-toastify";
import RepeatingEvents from "./components/RepeatingEvents";
import Admin from "./components/Admin";


// apollo client
const client = new ApolloClient({
    uri: process.env.REACT_APP_API_URL + '/graphql',
    cache: new InMemoryCache()
})

const ProtectedRoute = ({
  redirectPath = '/landing',
  children,
}) => {
  if (!authService.getCurrentUser()) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet/>;
}

const AdminRoute = ({
    redirectPath = '/',
    children,
}) => {
    if (authService.getCurrentUser() && authService.getCurrentUserName() == "Admin"){
        return children ? children : <Outlet/>
    }
    return <Navigate to={redirectPath} replace />;
}

function App() {
  const location = useLocation();
  return (
    <div className="bg-orange-100 min-h-screen">
        <NavBar/>
        <AnimatePresence mode="wait">
          <ApolloProvider client={client}>
                <Routes location={location} key={location.pathname}>
                  <Route element={<Eror404/>} path='*'></Route>
                  <Route element={<Home/>} path='/' exact></Route>
                  <Route element={<About/>} path='/about'></Route>
                  <Route element={<SingleTeam/>} path='/team'></Route>
                  <Route element={<Teams/>} path='/teams'></Route>
                  <Route element={<SingleNews/>} path='/post'></Route>
                  <Route element={<ProtectedRoute/>}>
                    <Route path='/booking' element={<Booking/>}/>
                  </Route>
                  <Route element={<LandingPageBooking/>} path='/landing'></Route>
                  <Route element={<Login/>} path='/login'></Route>
                  <Route element={<Register/>} path='/register'></Route>
                  <Route element={<AdminRoute/>}>
                      <Route element={<Admin/>} path='/admin'></Route>
                      <Route element={<RepeatingEvents/>} path='/repeating'></Route>
                  </Route>
                </Routes>
          </ApolloProvider>
        </AnimatePresence>
      <ToastContainer/>
    </div>
  )
}

export default App;
