import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL + "/auth/local";
class AuthService {
  login(username, password) {
    return axios
      .post(API_URL, {
        identifier: username,
        password: password
      })
      .then(response => {
        if (response.data.jwt) {
          localStorage.setItem("user", JSON.stringify(response.data.jwt));
          localStorage.setItem("username", JSON.stringify(username));
        }
        return response.data;
      });
  }
  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("username")
    window.location.reload();
  }
  register(username, email, password) {
    return axios.post(API_URL + "/register", {
      username,
      email,
      password
    });
  }
  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }
  getCurrentUserName(){
    return JSON.parse(localStorage.getItem('username'));
  }
  isCurrentUserAdmin() {
    if(JSON.parse(localStorage.getItem('username')) === 'Admin'){
      return true;
    }
    else {
      return false;
    }
  }
}
export default new AuthService();
