import { Link } from "react-router-dom";
import Transitions from "./Transition";
import { ArrowPathRoundedSquareIcon } from "@heroicons/react/24/outline"

export default function Admin() {
    return (
        <Transitions>
            <div className="p-12">
            <div className="container mx-auto">
                <h1 className="text-5xl text-gray-600 flex justify-center mb-12">Admin</h1>
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 place-items-center">
                    <div className="flex items-center m-4">
                        <Link to="/repeating">
                            <div className="rounded overflow-hidden shadow-lg bg-white p-4">
                                <div className="font-bold text-xl">Wiederholungsevent</div>
                                <div className="flex justify-center items-center">
                                    <ArrowPathRoundedSquareIcon className="h-16 lg:h-24 w-16 lg:w-24" />
                                </div>
                                
                            </div> 
                        </Link>
                    </div>
                </div>
            </div>
        </div>
        </Transitions>
    )
}