import { Link } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import ReactMarkdown from "react-markdown";
import { motion } from "framer-motion";

const POSTS = gql`
  query GetPosts {
    posts(sort: "Date:desc") {
      id,
      description,
      text,
      Date,
      image {url}
    }
  }
`

export default function News() {
    const { loading, error, data } = useQuery(POSTS)

    if (loading) return <p></p>
    if (error) return <p>Error</p>
    return(
        <div className="pt-12 px-12 pb-6">
            <motion.div className="container mx-auto" initial={{ opacity: 0}} whileInView={{ opacity: 1 }} viewport={{ margin:"-100px", once: true }}>
                <h1 id="news" className="text-5xl text-gray-600 flex justify-center mb-12">Neuigkeiten</h1>
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {data.posts.map(post => (
                    <div key={post.id} >
                        <Link to="/post" state={{ id: post.id }}>
                            <div className="rounded overflow-hidden shadow-lg bg-white max-w-md">
                                <img src={process.env.REACT_APP_API_URL + post.image.url} alt="Foto" className="object-cover w-full max-h-52" />
                                <div className="pt-4 text-center font-bold">{post.description}</div>
                                <p className="text-gray-700 text-base text-justify m-4 line-clamp-4">
                                    {post.text}
                                </p>
                            </div> 
                        </Link>
                    </div>
                    ))}
                </div>
            </motion.div>
        </div>
    )
}