import React from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar"
import axios from "axios";
import moment from "moment";
import "moment/locale/de"
import { toast} from 'react-toastify'
import { Types } from '../constants/bookingEnum'

import authService from "../services/auth.service";

import "react-big-calendar/lib/css/react-big-calendar.css"
import 'react-toastify/dist/ReactToastify.css'
import "../App.css"

const authHeader= () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      return { Authorization: 'Bearer ' + user };
    } else {
      return {};
    }
}


const localizer = momentLocalizer(moment);

const resourceMap = [
    { resourceId: 1, resourceTitle: 'Platz 1' },
    { resourceId: 2, resourceTitle: 'Platz 2' },
    { resourceId: 3, resourceTitle: 'Platz 3' },
  ]



class Booking extends React.Component {

    constructor(props) {
        super(props)
    
        this.state = {
          cal_events: [
            //State is updated via componentDidMount
          ],
        }
    
      }

    componentDidMount() {
        let config = {
            headers: authHeader(),
            params: {
                _sort: "start:ASC",
                start_gte: `${moment().format('YYYY-MM-DD')}`,
                start_lt: `${moment().add(16, 'days').format('YYYY-MM-DD')}`
                
            }
        }
        axios.get(process.env.REACT_APP_API_URL + '/events',  config)
            .then(res => {
                const events = res.data;
                for (let i = 0; i < events.length; i++) {
                    events[i].start = moment(events[i].start).toDate();
                    events[i].end = moment(events[i].end).toDate();
                }
            
                this.setState({
                    cal_events:events
                })
            })
    }

    handleSelect = ({ start, end, resourceId}) => {
        let available = true;
        let inRange = start < moment().add(14, 'days');
        if (inRange){
            for (let i = 0; i < this.state.cal_events.length; i++){
                if (resourceId === this.state.cal_events[i].resourceId && start > this.state.cal_events[i].start && start < this.state.cal_events[i].end){
                    available = false
                    break
                }
                else if (resourceId === this.state.cal_events[i].resourceId && end > this.state.cal_events[i].start && end < this.state.cal_events[i].end){
                    available = false
                    break
                }
                else if (resourceId === this.state.cal_events[i].resourceId && end > this.state.cal_events[i].start && start < this.state.cal_events[i].end){
                    available = false
                    break
                }
            }
            if (available) {
                const title = window.prompt('Name eingeben')
                if (title && available) {
                    var self = this
                    axios.post(process.env.REACT_APP_API_URL + '/events', {
                        title: title,
                        start: start,
                        end: end,
                        resourceId: resourceId,
                        typeId: Types.User
                    }, {
                        headers: authHeader()
                    }
                    )
                        .then(function (response) {
                            self.setState({
                                cal_events: [
                                    ...self.state.cal_events,
                                    {
                                        start,
                                        end,
                                        title,
                                        resourceId,
                                        id: response.data.id,
                                        typeId: response.data.typeId
                                    },
                                ],
                            })
                            toast.success("Event gespeichert")
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                }
            }

            else {
                toast.error("Zeitslot bereits belegt")
            }
        }
        else {
            toast.error("Buchungen nur 14 Tage im Voraus möglich")
        }
    }

    onSelectEvent(event) {
        console.log(this.state.cal_events.length)
        const r = window.confirm("Wollen Sie den Eintrag löschen")
        if(r === true) {
            var self = this
            axios.delete(process.env.REACT_APP_API_URL + '/events/' + event.id, { headers: authHeader()})
                .then(function(response) {
                    console.log(response)
                     self.setState(() => {
                         const idx = self.state.cal_events.indexOf(event)
                         self.state.cal_events.splice(idx, 1)
                         console.log(self.state.cal_events[idx])
                     })
                    window.location.reload();   //not perfect
                    toast.warn("Event gelöscht")
                })
            
            }
    }
    
    render() {
        return(
                <div>
                    <Calendar
                    selectable
                    events={this.state.cal_events}
                    localizer={localizer}
                    culture="de"
                    defaultView={Views.DAY}
                    views={['day']}
                    step={30}
                    min={new Date(2022, 1, 27, 8, 0, 0)}
                    max={new Date(2022, 1, 27, 21, 0, 0)}
                    resources={resourceMap}
                    resourceIdAccessor="resourceId"
                    resourceTitleAccessor="resourceTitle"
                    onSelectEvent={event => this.onSelectEvent(event)}
                    onSelectSlot={this.handleSelect}
                    messages={{
                        previous: 'Gestern',
                        today: `${moment().format('DD.MM.YY')}`,
                        next: 'Morgen'
                    }}
                    eventPropGetter={(event) => {
                        let backgroundColor;
                        switch (event.typeId){
                            case Types.User:
                                backgroundColor = '#3174ad';
                                break;
                            case Types.Guest:
                                backgroundColor = 'sandybrown';
                                break;
                            case Types.Training:
                                backgroundColor = 'mediumseagreen';
                                break;
                            case Types.Game:
                                backgroundColor = 'firebrick';
                                break;
                            default:
                                backgroundColor = 'gray';
                                break;
                        }
                        return { style: { backgroundColor}};
                    }}
                    />
                    <div>
                    <button onClick={authService.logout} className="m-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Logout</button>
                    </div>
                </div>
            )
        }
}

export default Booking
