import { Link } from "react-router-dom";

export default function LandingPageBooking() {
    return(
        <div className="grid grid-cols-2 p-12">
            <div className="text-center">
                <Link to="/login">
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Mitglieder</button>
                </Link>
            </div>
            
            <div class="text-center">
                    <button className="bg-blue-500 text-white font-bold py-2 px-4 rounded opacity-50 cursor-not-allowed">Gäste</button>
            </div>
        </div>
    )
}